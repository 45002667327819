import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import fetchXMLFile from "../../services/xmlfile";
import ProcessedData from "../../components/processed-data/processed-data";
import { Editor } from "primereact/editor";
import * as constants from "../../constants/constants";
import { Menubar } from "primereact/menubar";
import { ConfirmDialog } from "primereact/confirmdialog";
// import { TieredMenu } from 'primereact/tieredmenu';

const Table = (props) => {
  const {
    content: data,
    columnsData: columns,
    tableStyle,
    paginatorTemplate,
    paginator,
    scrollable,
    rows,
    footer,
    header,
    rowsPerPageOptions,
    emptyMessage,
    currentPageReportTemplate,
    rowExpansionColumnsData,
    rowExpansionLogSummaryColumnsErrorsData,
    styles,
    deleteRecord,
    reProcessRecord,
    proceddAllRecords,
    showDeleteConfirmation,
    SetShowDeleteConfirmation,
    setShowReprocesConfirmation,
    showReProcessConfirmation,
    setShowProceedAllConfirmation,
    showProceedAllConfirmation,
    isLogSummary
  } = props;
  const menu = useRef(null);
  const [expandedRows, setExpandedRows] = useState(null);
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);
  const [xmlfile, setXmlfile] = useState("");
  const [lineitemsData, setLineItemsData] = useState("");
  const [showLineItems, setShowLineItems] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [
    numberOfProcessedFilesToBeDeleted,
    SetNumberOfProcessedFilesToBeDeleted
  ] = useState(0);
  const [totalNumberOfFiles, setTotalNumberOfFiles] = useState(0);
  const [failedItemsCount, setFailedItemsCount] = useState(0);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const errorPosition = "center";

  const toggleMenu = (e) => {
    menu.current.toggle(e);
  };

  useEffect(() => {
    setExpandedRows(null);
  }, [isLogSummary]);
  const showModal = async (rawData) => {
    try {
      setXmlfile("");
      let result = "";
      result = await fetchXMLFile({ blobName: rawData?.fileName });
      setXmlfile(result);
      setVisible(true);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 4000
      });
    }
  };

  const lineItems = (rowData) => {
    setShowLineItems(true);
    setLineItemsData(rowData);
  };

  const copyxmlfile = () => {
    navigator.clipboard
      .writeText(xmlfile)
      .then((e) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "XML File Copied Successfully",
          life: 4000
        });
        setVisible(false);
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: e.message,
          life: 4000
        });
        setVisible(false);
      });
  };

  const allowExpansion = (rowData) => {
    return (
      rowData?.logSummaryFileDTOs?.length > 0 ||
      rowData?.unprocessedErrors?.length > 0
    );
  };
  const rowClass = (data) => {
    return {
      "bg-lightYellow": data?.fileUrl && !isLogSummary == null
    };
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-1 pl-5 ml-5" style={{ background: "white" }}>
        <h6
          style={{
            fontSize: "20px",
            fontWeight: "400",
            margin: "1px",
            color: "#004F74",
            border: "1px solid #E9ECEF",
            background: "rgba(15, 164, 233, 0.10)"
          }}
        >
          Items Information
        </h6>
        <DataTable
          value={data.logSummaryFileDTOs}
          rows={constants.defaultRows}
          rowsPerPageOptions={constants.rowsPerPageOptions}
          paginator={constants.paginator}
          scrollable={constants.scrollable}
          rowClassName={rowClass}
        >
          {rowExpansionColumnsData?.map((col, i) =>
            col?.columnType?.toLocaleLowerCase() === "fun" ? (
              <Column
                key={col?.field}
                field={col?.field}
                header={col?.header}
                sortable={col?.sort}
                style={{
                  width: col?.width,
                  fontSize: "14px",
                  maxWidth: col?.maxWidth
                }}
                headerStyle={{
                  width: col?.width,
                  minWidth: col.minWidth,
                  maxWidth: col?.maxWidth
                }}
                body={(e) => col.columnDefination(e, showModal, lineItems)}
              />
            ) : (
              <Column
                key={col?.field}
                field={col?.field}
                header={col?.header}
                sortable={col?.sort}
                style={{
                  width: col?.width,
                  fontSize: "14px",
                  maxWidth: col?.maxWidth
                }}
                headerStyle={{
                  width: col?.width,
                  minWidth: col.minWidth,
                  maxWidth: col?.maxWidth
                }}
                body={
                  col?.field?.toLocaleLowerCase() === "errors"
                    ? props?.createOrderErrorSplit
                    : null
                }
              />
            )
          )}
        </DataTable>
        {data.unprocessedErrors?.length > 0 && (
          <>
            <h6
              style={{
                fontSize: "20px",
                fontWeight: "400",
                margin: "1px",
                color: "#A52121",
                border: "1px solid #E9ECEF",
                background: "#FEF2F2"
              }}
            >
              Unprocessed Error Items
            </h6>
            <DataTable
              value={data.unprocessedErrors}
              rows={constants.defaultRows}
              rowsPerPageOptions={constants.rowsPerPageOptions}
              paginator={constants.paginator}
              scrollable={constants.scrollable}
            >
              {rowExpansionLogSummaryColumnsErrorsData?.map((col, i) =>
                col?.columnType?.toLocaleLowerCase() === "fun" ? (
                  <Column
                    key={col?.field}
                    field={col?.field}
                    header={col?.header}
                    sortable={col?.sort}
                    style={{ minWidth: col?.minWidth, fontSize: "14px" }}
                    headerStyle={{ minWidth: col.minWidth }}
                    body={(e) =>
                      col.columnDefination(e, setShowErrorDialog, setErrorList)
                    }
                  />
                ) : (
                  <Column
                    key={col?.field}
                    field={col?.field}
                    header={col?.header}
                    sortable={col?.sort}
                    style={{ minWidth: col?.minWidth, fontSize: "14px" }}
                    headerStyle={{ minWidth: col.minWidth }}
                    body={
                      col?.field?.toLocaleLowerCase() === "errors"
                        ? props?.createOrderErrorSplit
                        : null
                    }
                  />
                )
              )}
            </DataTable>
          </>
        )}
      </div>
    );
  };
  const footerContent = (
    <div className="col-12 grid">
      <div className="col-12 md:col-2">
        <Button
          label="OK"
          size="medium"
          className={styles.primaryBtn}
          onClick={() => setVisible(false)}
          autoFocus
        />
      </div>
      <div className="col-12 md:col-2 md:col-offset-8">
        <Button
          label="Copy"
          size="medium"
          className={styles.copyBtn}
          onClick={() => copyxmlfile()}
          autoFocus
        />
      </div>
    </div>
  );

  const orderActionTemplate = (rowData) => {
    let failedRecordsCount = rowData?.logSummaryFileDTOs?.filter(
      (item) => item?.status == "Failed"
    )?.length;

    let isEligibleForProceedCount = rowData?.logSummaryFileDTOs?.filter(
      (item) => item?.isEligibleForProceed == true
    )?.length;

    return (
      <div className="flex gap-2">
        <Button
          icon="pi pi-refresh"
          rounded
          text
          raised
          severity="secondary"
          aria-label="Reprocess All"
          disabled={failedRecordsCount > 0 ? false : true}
          onClick={() => onReProcessAllHandler(rowData, failedRecordsCount)}
          tooltip="Reprocess All - It reprocesses the failed orders due to errors in D365."
        />

        {!isLogSummary && (
          <Button
            icon="pi pi-forward"
            rounded
            text
            raised
            severity="secondary"
            aria-label="Proceed All"
            disabled={isEligibleForProceedCount > 0 ? false : true}
            onClick={() => onProceedAllHandler(rowData)}
            tooltip="Proceed All - It will skip the error line items and continue processing all the remaining successful records."
          />
        )}

        <Button
          icon="pi pi-trash"
          rounded
          text
          raised
          severity="secondary"
          aria-label="Delete All"
          onClick={() => onDeleteAllHandler(rowData)}
          tooltip="Delete All - It deletes the entire file."
        />
      </div>
    );
  };

  const onReProcessAllHandler = (rowData, failedRecordsCount) => {
    setSelectedRecord(rowData);
    setFailedItemsCount(failedRecordsCount);
    setTotalNumberOfFiles(rowData?.numberOfFiles);
    setShowReprocesConfirmation(true);
  };

  const onProceedAllHandler = (rowData) => {
    setSelectedRecord(rowData);
    setShowProceedAllConfirmation(true);
  };

  const onDeleteAllHandler = (rowData) => {
    setSelectedRecord(rowData);
    SetNumberOfProcessedFilesToBeDeleted(rowData?.successFiles);
    setTotalNumberOfFiles(rowData?.numberOfFiles);
    SetShowDeleteConfirmation(true);
  };

  const deleteItem = () => {
    deleteRecord(selectedRecord);
  };

  const reprocessItems = () => {
    reProcessRecord(selectedRecord);
  };

  const proceedAllItems = () => {
    proceddAllRecords(selectedRecord);
  };

  return (
    <>
      <Dialog
        header="File Details"
        visible={visible}
        style={{ width: "50vw" }}
        footer={footerContent}
        onHide={() => setVisible(false)}
      >
        <pre className="mb-5">{xmlfile}</pre>
      </Dialog>
      <Dialog
        header="Line Items"
        visible={showLineItems}
        style={{ width: "80vw" }}
        onHide={() => setShowLineItems(false)}
      >
        <ProcessedData processedData={lineitemsData} fromscreen="autolog" />
      </Dialog>
      {showErrorDialog && (
        <Dialog
          header="Error list"
          visible={showErrorDialog}
          style={{ width: "50vw" }}
          position={errorPosition}
          onHide={() => setShowErrorDialog(false)}
        >
          <ul className={styles.xmlList}>
            {errorList?.map((item) => {
              return <li className={styles.xmlListItem}>{item}</li>;
            })}
          </ul>
        </Dialog>
      )}
      <Toast ref={toast} position="top-center" />
      <DataTable
        value={data}
        tableStyle={tableStyle}
        paginatorTemplate={paginatorTemplate}
        paginator={paginator}
        scrollable={scrollable}
        className={`${tableStyle.dataTable}`}
        rows={rows}
        footer={footer}
        header={header}
        rowsPerPageOptions={rowsPerPageOptions}
        emptyMessage={emptyMessage}
        currentPageReportTemplate={currentPageReportTemplate}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="id"
      >
        <Column style={{ width: "10px" }} body={orderActionTemplate} />
        <Column expander={allowExpansion} style={{ width: "10px" }} />
        {columns?.map((col, i) => (
          <Column
            key={col?.field}
            field={col?.field}
            header={col?.header}
            sortable={col?.sort}
            style={{ minWidth: col?.minWidth }}
            headerStyle={{ minWidth: col.minWidth }}
            body={
              col?.columnType?.toLocaleLowerCase() === "fun"
                ? col.columnDefination
                : col?.field?.toLocaleLowerCase() === "errors"
                ? props?.createOrderErrorSplit
                : null
            }
          />
        ))}
      </DataTable>
      {showDeleteConfirmation && (
        <ConfirmDialog
          visible={showDeleteConfirmation}
          message={
            `${numberOfProcessedFilesToBeDeleted}` > 0
              ? ` (${numberOfProcessedFilesToBeDeleted}/${totalNumberOfFiles}) Records will be deleted.Are you
                      sure you want to delete the records?`
              : `Are you sure you want to delete the records?`
          }
          header="Confirmation"
          icon="pi pi-exclamation-triangle"
          style={{ width: "50vw", color: "red" }}
          breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
          accept={() => deleteItem()}
          onHide={() => SetShowDeleteConfirmation(false)}
        ></ConfirmDialog>
      )}
      {showReProcessConfirmation && (
        <ConfirmDialog
          visible={showReProcessConfirmation}
          message={`Only XML (${failedItemsCount}/${totalNumberOfFiles}) will be reprocessed, Not the error records!`}
          header="Note"
          icon="pi pi-exclamation-circle"
          style={{ width: "50vw", color: "red" }}
          breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
          accept={() => reprocessItems()}
          onHide={() => setShowReprocesConfirmation(false)}
        ></ConfirmDialog>
      )}
      {showProceedAllConfirmation && (
        <ConfirmDialog
          visible={showProceedAllConfirmation}
          message="Error records will not be processed any more. Are you sure you want to proceed with success records?"
          header="Note"
          icon="pi pi-exclamation-triangle"
          style={{ width: "50vw", color: "red" }}
          breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
          accept={() => proceedAllItems()}
          onHide={() => setShowProceedAllConfirmation(false)}
        ></ConfirmDialog>
      )}
    </>
  );
};

export default Table;
